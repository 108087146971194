body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.error-text
{
  color: red;
  font-size: 15px;
}
.header_name
{
  font-size: 28px;
  color: white;
  padding: 15px;
  font-weight: bold;
  text-align: center;
}
.content-box {
  background-color: #f8f8f8;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px;
  margin: 20px auto;

}





.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: rgb(0, 118, 148);
}

.circle-content {
  position: relative;
  text-align: center;
  color: white;
}

.circle-character {
  font-size: 35px;
}

.circle-overlay {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0px 4px;
  font-size: 10px;
  border-radius: 4px;
  cursor: pointer;
}
.my_background {
  background: #007694;
}

.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
 
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}


